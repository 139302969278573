<template lang="pug">
.flex(:class="{ error: errorShow }")
  .flex.items-center(@click="setValue")
    .checkbox
      svgIcon.text-gray-300(
        :path="mdiCheckboxBlankOutline",
        :size="24",
        type="mdi"
      )
      svgIcon.absolute.pt-1.pl-1.text-green-600(
        :path="mdiCheckBold",
        :size="20",
        type="mdi",
        v-show="modelValue"
      )
    LabelInput.label {{ label }}
</template>
<script>
import LabelInput from "./label.input";
import { mdiCheckboxBlankOutline, mdiCheckBold } from "@mdi/js";
export default {
  name: "checkbox.input",
  emits: ["update:modelValue"],
  components: {
    LabelInput,
  },
  props: {
    label: {
      type: String,
      default: "--",
    },
    errorShow: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdiCheckboxBlankOutline,
    mdiCheckBold,
  }),
  methods: {
    setValue() {
      this.$emit("update:modelValue", !this.modelValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox {
  @apply flex cursor-pointer;
}

.label {
  @apply ml-2 cursor-pointer;
}

.error .label {
  @apply text-red-400;
}
</style>