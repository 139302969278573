<template lang="pug">
ModalBase(:show="isPayed")
  .button-close(@click="$store.commit('cartStore/setCartPayed', false)")
    svgIcon(:path="mdiClose", type="mdi", :size="16")
  .flex.flex-col
    h1 Pago procesado correctamente
    p Muchas gracias por confiar en Boomfy!
    p Presta atención a tu correo electronico, en menos de 5 minutos recibiras un correo para confirmar el pedido.
</template>
<script>
import { mdiCheckBold, mdiClose } from "@mdi/js";
import ModalBase from "@/components/structure/modal";

export default {
  components: {
    ModalBase,
  },
  data: () => ({
    mdiCheckBold,
    mdiClose,
    show: false,
  }),
  computed: {
    isPayed() {
      return this.$store.state.cartStore.cart_payed;
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  @apply font-bold text-gray-600 text-xl text-center mb-4;
}

p {
  @apply text-sm text-center;

  &:first-of-type {
    @apply font-semibold text-blue-600;
  }
  + p {
    @apply mt-2;
  }
}

.button-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
  @apply text-indigo-300 flex text-xs items-center;
  &:hover {
    @apply text-indigo-500;
  }
}
</style>