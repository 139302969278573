<template lang="pug">
.input(:class="{ error: errorShow }")
  LabelInput {{ label }}
  .input-box
    .icon(:class="{ active: isActiveData || isActive }")
      svgIcon(:path="iconPath", v-if="iconPath", type="mdi", :size="24")
    .flex.items-center.w-full.pl-2(v-if="!!$slots['customContent']")
      slot(name="customContent")
    input(
      type="text",
      v-if="!$slots['customContent']",
      :placeholder="placeholder",
      :value="modelValue",
      @focus="isActiveData = true",
      @blur="isActiveData = false",
      @input="$emit('update:modelValue', $event.target.value)"
    )
  .error-message(v-if="errorShow && errorMessage") {{ errorMessage }}
</template>
<script>
import LabelInput from "./label.input";
export default {
  name: "text.input",
  emits: ["update:modelValue"],
  components: {
    LabelInput,
  },
  props: {
    isActive: {
      type: Boolean,
      default: undefined,
    },
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "--",
    },
    iconPath: {
      type: String,
      default: null,
    },
    errorShow: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isActiveData: false,
  }),
};
</script>
<style lang="scss" scoped>
.input {
  @apply block;

  .input-box {
    @apply flex bg-white border-2 rounded-md border-gray-200;

    .icon {
      width: 42px;
      height: 42px;
      @apply bg-gray-200 flex justify-center items-center flex-shrink-0 text-gray-500 transition-colors;

      &.active {
        @apply text-blue-500;
      }
    }

    input {
      @apply bg-transparent outline-none pl-2 w-full;
    }
  }

  & + .input {
    @apply mt-4;
  }

  &.error {
    .input-box {
      @apply border-red-200;
      .icon {
        @apply bg-red-200 text-red-500;
      }
    }
  }

  .error-message {
    @apply text-xs font-normal text-red-500 pt-1;
  }
}
</style>