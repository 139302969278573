<template lang="pug">
XyzTransition(appear, xyz="fade")
  .loader(v-if="show")
    .circle.circle-1.xyz-nested(xyz="small")
</template>
<script>
export default {
  name: "loader.element",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff50;
  @apply z-50 flex justify-center items-center;

  .circle {
    width: 46px;
    height: 46px;
    color: #3b82f6;
    background-image: url(~@/assets/svg/loader.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>