<template lang="pug">
label 
  slot
</template>
<script>
export default {
  name: "label.input",
};
</script>
<style lang="scss" scoped>
label {
  @apply text-sm font-medium text-gray-500;

  &::selection {
    user-select: none;
  }
}
</style>