<template lang="pug">
ModalBase(:show="show")
  .button-close(@click="$store.commit('cartStore/setProductId', null)")
    | {{ $t('form_payment.change_plan') }}
    svgIcon(:path="mdiClose", type="mdi", :size="16")
  LoaderElement(:show="isRunPayment")
  .flex.flex-col.flex-grow
    .title
      svgIcon(:path="platformIcon", type="mdi", :size="20")
      h1 {{ title }}
    .mb-6.flex
      .price {{ price }}
    TextInput(
      :label="labelLink",
      :iconPath="mdiAt",
      :placeholder="`bellapoarch`",
      v-model="form.link",
      :errorShow="form.error_link",
      :errorMessage="$t('form_payment.error_link')"
    )
    TextInput(
      :label="$t('form_payment.user_email')",
      :iconPath="mdiEmail",
      :placeholder="`valid@email.com`",
      v-model="form.email",
      :errorShow="form.error_email",
      :errorMessage="$t('form_payment.error_email')"
    )
    TextInput(
      :label="$t('form_payment.card_num')",
      :iconPath="mdiCreditCard",
      :isActive="card_is_focus",
      :errorShow="card_error",
      :errorMessage="$t('form_payment.error_card')"
    )
      template(#customContent)
        #card-element.element
    .grid-card
      div
        TextInput(
          :label="$t('form_payment.card_exp')",
          :iconPath="mdiCreditCardClock",
          :isActive="date_is_focus",
          :errorShow="date_error"
        )
          template(#customContent)
            #card-expiry.element
      div
        TextInput(
          :label="`CVV:`",
          :iconPath="mdiCreditCardMinus",
          :isActive="cvc_is_focus",
          :errorShow="cvc_error"
        )
          template(#customContent)
            #card-cvc.element
    CheckboxInput.mt-4(
      v-model="form.check_terms",
      :label="$t('form_payment.checkbox_terms')",
      :errorShow="form.error_check"
    )
    .flex.flex-col.mt-2
      ButtonInput.flex-grow(
        :textButton="$t('form_payment.button_payment')",
        @click="processPayment"
      )
      p.error-generic(v-if="genericError") {{ $t('form_payment.error_generic') }}
</template>
<script>
import TextInput from "@/components/inputs/text.input";
import ButtonInput from "@/components/inputs/button.input";
import CheckboxInput from "@/components/inputs/checkbox.input";
import LoaderElement from "@/components/elements/loader.element";
import ModalBase from "@/components/structure/modal";

import { loadStripe } from "@stripe/stripe-js";

import {
  mdiInstagram,
  mdiCustomTikTok,
  mdiEmail,
  mdiAt,
  mdiClose,
  mdiCreditCard,
  mdiCreditCardClock,
  mdiCreditCardMinus,
} from "@/plugins/iconFinder";
import { setPrice } from "@/plugins/numbers";

const style = {
  style: {
    base: {
      color: "#374151",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "16px",
      fontWeight: "400",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "rgba(209, 213, 219, 1)",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "16px",
        fontWeight: "400",
      },
    },
    invalid: {
      color: "#ff4848",
    },
  },
};

export default {
  name: "modal.payment",
  components: {
    TextInput,
    ButtonInput,
    CheckboxInput,
    LoaderElement,
    ModalBase,
  },
  data: () => ({
    mdiEmail,
    mdiAt,
    mdiClose,
    mdiCreditCard,
    mdiCreditCardClock,
    mdiCreditCardMinus,
    form: {
      link: "",
      email: "",
      check_terms: false,
      error_email: false,
      error_link: false,
      error_check: false,
    },
    reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    stripe: null,
    card_error: false,
    date_error: false,
    cvc_error: false,
    card_complete: false,
    date_complete: false,
    cvc_complete: false,
    card_empty: true,
    date_empty: true,
    cvc_empty: true,
    card_is_focus: false,
    date_is_focus: false,
    cvc_is_focus: false,
    isRunPayment: false,
    genericError: false,
    card: null,
    exp: null,
    cvc: null,
  }),
  props: {
    show: Boolean,
  },
  watch: {
    show(nValue) {
      if (nValue) {
        this.$gtag.event("open_payment_box");
        setTimeout(() => {
          this.loadStripe();
        }, 500);
      } else {
        this.card.unmount();
        this.exp.unmount();
        this.cvc.unmount();
      }
    },
    "form.email"() {
      this.showErrorEmail(false);
    },
    "form.link"() {
      this.showErrorLink(false);
    },
    "form.check_terms"() {
      this.showErrorCheckTerms(false);
    },
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC);
  },
  methods: {
    loadStripe() {
      const elements = this.stripe.elements({
        fonts: [
          {
            cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400",
          },
        ],
      });
      this.card = elements.create("cardNumber", style);
      this.exp = elements.create("cardExpiry", style);
      this.cvc = elements.create("cardCvc", style);

      this.card.addEventListener("change", ({ error, empty, complete }) => {
        this.card_complete = complete ? true : false;
        this.card_empty = empty ? true : false;
        this.card_error = error ? true : false;
        //this.hideErrorPayment();
      });
      this.exp.addEventListener("change", ({ error, empty, complete }) => {
        this.date_complete = complete ? true : false;
        this.date_empty = empty ? true : false;
        this.date_error = error ? true : false;
        //this.hideErrorPayment();
      });
      this.cvc.addEventListener("change", ({ error, empty, complete }) => {
        this.cvc_complete = complete ? true : false;
        this.cvc_empty = empty ? true : false;
        this.cvc_error = error ? true : false;
        //this.hideErrorPayment();
      });
      this.card.addEventListener("focus", () => (this.card_is_focus = true));
      this.card.addEventListener("blur", () => (this.card_is_focus = false));

      this.exp.addEventListener("focus", () => (this.date_is_focus = true));
      this.exp.addEventListener("blur", () => (this.date_is_focus = false));

      this.cvc.addEventListener("focus", () => (this.cvc_is_focus = true));
      this.cvc.addEventListener("blur", () => (this.cvc_is_focus = false));

      this.card.mount("#card-element");
      this.exp.mount("#card-expiry");
      this.cvc.mount("#card-cvc");
    },
    processPayment() {
      const isMailValid = this.isEmailValid(this.form.email);
      this.showErrorEmail(!isMailValid);
      const isLinkValid = this.form.link != "";
      this.showErrorLink(!isLinkValid);
      const isChecked = this.form.check_terms;
      this.showErrorCheckTerms(!isChecked);
      if (this.card_empty) this.card_error = true;
      if (this.date_empty) this.date_error = true;
      if (this.cvc_empty) this.cvc_error = true;

      if (
        isMailValid &&
        isLinkValid &&
        isChecked &&
        !this.isRunPayment &&
        this.verificationDataPayment()
      ) {
        this.isRunPayment = true;
        this.genericError = false;
        this.stripe
          .createPaymentMethod({
            type: "card",
            card: this.card,
          })
          .then(async ({ paymentMethod, error }) => {
            if (error) this.genericError = true;
            if (paymentMethod) {
              const result = await this.$store.dispatch("cartStore/checkout", {
                link_to_promote: this.form.link,
                user_email: this.form.email,
                token_id: paymentMethod.id,
              });
              await this.processResultPayment(result);
            }
          })
          .finally(() => (this.isRunPayment = false));
      }
    },
    async processResultPayment(response) {
      // console.log(response);
      if (response.error) {
        // Show error from server on payment form
      } else if (response.requires_action) {
        // Use Stripe.js to handle the required card action
        const {
          error: errorAction,
          paymentIntent,
        } = await this.stripe.confirmCardPayment(
          response.payment_intent_client_secret
        );
        if (errorAction) {
          // Show error from Stripe.js in payment form
          this.genericError = true;
          // console.log(errorAction);
        } else {
          const result = await this.$store.dispatch("cartStore/verification", {
            link_to_promote: this.form.link,
            user_email: this.form.email,
            token_id: paymentIntent.id,
          });
          await this.processResultPayment(result);
        }
      } else {
        this.$gtag.event("purchase", {
          event_category: "conversion",
        });
        this.$store.commit("cartStore/setProductId", null);
        this.$store.commit("cartStore/setCartPayed");
        // Show success message
      }
    },
    verificationDataPayment() {
      return (
        this.card_complete &&
        this.cvc_complete &&
        this.date_complete &&
        !this.card_error &&
        !this.date_error &&
        !this.cvc_error
      );
    },
    isEmailValid(email) {
      return this.reg.test(email) ? true : false;
    },
    showErrorEmail(show) {
      this.form.error_email = show ? true : false;
    },
    showErrorLink(show) {
      this.form.error_link = show ? true : false;
    },
    showErrorCheckTerms(show) {
      this.form.error_check = show ? true : false;
    },
  },
  computed: {
    product() {
      const product = this.$store.getters["cartStore/productData"];
      return product;
    },
    title() {
      return `${this.product.cuantity} ${this.productType} - ${this.platformName}`;
    },
    platformIcon() {
      switch (this.product.platform) {
        case "tiktok":
          return mdiCustomTikTok;
        case "instagram":
          return mdiInstagram;
        default:
          return null;
      }
    },
    platformName() {
      switch (this.product.platform) {
        case "tiktok":
          return "TikTok";
        case "instagram":
          return "Instagram";
        default:
          return this.product.platform;
      }
    },
    productType() {
      switch (this.product.type_product) {
        case "followers":
          return this.$t("followers");
        case "likes":
          return this.$t("likes");
        case "views":
          return this.$t("views");
        default:
          return this.product.type_product;
      }
    },
    labelLink() {
      switch (this.product.type_product) {
        case "followers":
          return this.$t("form_payment.label_link_to_promote_followers");
        case "likes":
          return this.$t("form_payment.label_link_to_promote_likes");
        case "views":
          return this.$t("form_payment.label_link_to_promote_views");
        default:
          return this.product.type_product;
      }
    },
    price() {
      return setPrice(this.product.price, this.product.sign);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
  @apply text-indigo-300 flex text-xs items-center;
  &:hover {
    @apply text-indigo-500;
  }
}

.title {
  @apply flex items-center pt-4;
  svg {
    @apply text-yellow-500;
  }
  h1 {
    @apply font-bold text-gray-600 text-xl pl-3;
  }
}

.price {
  @apply text-sm font-normal text-white bg-green-500 rounded-full px-4 leading-5;
}

.element {
  width: 100%;
  height: auto;
}

.error-generic {
  @apply text-center mt-2 text-xs text-red-500;
}

.grid-card {
  @apply grid grid-cols-2 gap-2 mt-4;
}

@screen phone {
  .grid-card {
    @apply gap-6;
  }
}
</style>