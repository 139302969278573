<template lang="pug">
.button {{ textButton }}
</template>
<script>
export default {
  name: "button.input",
  props: {
    textButton: {
      type: String,
      default: "--",
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  @apply mt-2 py-3 bg-blue-500 text-white rounded-md font-semibold text-center px-4;
}
</style>