<template lang="pug">
teleport(to="body", v-if="show")
  .back-modal
    XyzTransition(appear, duration="auto")
      .background-lineal(xyz="fade")
    .box-modal
      XyzTransition(appear, duration="auto", xyz="small-5 fade")
        .card-modal
          .back-blur
            slot
</template>
<script>
export default {
  name: "modal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.back-modal {
  @apply fixed w-full h-full top-0 left-0 z-50 overflow-y-auto overflow-x-hidden;
  .background-lineal {
    background: rgba(255, 255, 255, 0.45);
    //backdrop-filter: blur(2px);
    @apply w-full h-full top-0 left-0 absolute;
  }
}

.box-modal {
  .card-modal {
    @apply max-w-full w-full min-h-full relative;
  }
}

.back-blur {
  background: rgba(255, 255, 255, 1);
  --tw-shadow: 0 5px 35px -8px rgba(0, 0, 0, 0.15);
  box-shadow: var(--tw-shadow);
  z-index: 0;
  @apply w-full min-h-screen px-6 py-8 overflow-hidden relative flex justify-center items-center;

  &::before {
    content: "";
    width: 400px;
    height: 400px;
    border-radius: 300px;
    position: absolute;
    top: -120px;
    left: -120px;
    z-index: -1;
    @apply bg-blue-50;
    opacity: 1;
  }
}

@screen phone {
  .box-modal {
    @apply flex justify-center items-center min-h-screen;
    .card-modal {
      @apply max-w-md w-full relative h-auto min-h-0;
      .back-blur {
        @apply min-h-0 rounded-lg;
      }
    }
  }
}
</style>