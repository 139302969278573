<template lang="pug">
.container.text-center
  .min-h-screen.pt-52
    H1Element {{ $t('plans.h1') }}
    p.mt-6 {{ $t('plans.subtitle') }}
    .grid-menu
      .button-menu.button1(@click="setFilterPlatform('tiktok')")
        .button(:class="{ active: filter_platform == 'tiktok' }") TikTok
      .button-menu.button2(@click="setFilterPlatform('instagram')")
        .button(:class="{ active: filter_platform == 'instagram' }") Instagram
    .spacer
    .group-filters
      .button(
        :class="{ active: filter_type_product == 'followers' }",
        @click="setFilterTypeProduct('followers')"
      ) {{ $t('followers') }}
      .button(
        :class="{ active: filter_type_product == 'likes' }",
        @click="setFilterTypeProduct('likes')"
      ) {{ $t('likes') }}
      .button(
        :class="{ active: filter_type_product == 'views' }",
        @click="setFilterTypeProduct('views')"
      ) {{ $t('views') }}
    .flex.flex-wrap.gap-8.justify-center.mt-16.pb-24
      CardPrice(
        v-for="product in listProducts",
        :key="product._id",
        :product="product",
        v-on:product-selected="setProductSelected"
      )

CorrectPayment
ModalPayment(:show="showModalPayment")
teleport(to="head")
  title {{ $t('metas.plans_title') }}
  meta(name="description", :content="$t('metas.plans_description')")
  meta(name="Robots", content="noindex,nofollow")
</template>
<script>
import CardPrice from "@/components/cards/card.prices";
import ModalPayment from "@/components/payment/modal.payment";
import CorrectPayment from "@/components/payment/correct.payment";
import H1Element from "@/components/elements/h1.element";

export default {
  name: "plans",
  components: {
    H1Element,
    CardPrice,
    ModalPayment,
    CorrectPayment,
  },
  props: {
    filter: {
      type: String,
      default: null,
    },
    platform: {
      type: String,
      default: null,
    },
  },
  watch: {
    filter() {
      this.setFilterTypeProduct(this.filter || "followers");
    },
    platform() {
      this.setFilterPlatform(this.platform || "tiktok");
    },
  },
  created() {
    this.setFilterPlatform(this.platform || "tiktok");
    this.setFilterTypeProduct(this.filter || "followers");
  },
  methods: {
    setFilterPlatform(platform) {
      this.$router.push({
        name: "PlansPage",
        query: { platform, filter: this.filter_type_product },
      });
      this.$store.commit("productsStore/setFilterPlatform", platform);
    },
    setFilterTypeProduct(type) {
      this.$router.push({
        name: "PlansPage",
        query: { platform: this.filter_platform, filter: type },
      });
      this.$store.commit("productsStore/setFilterTypeProduct", type);
    },
    setProductSelected(product_id) {
      this.$store.commit("cartStore/setProductId", product_id);
    },
  },
  computed: {
    listProducts() {
      return this.$store.getters["productsStore/getListProducts"];
    },
    filter_platform() {
      return this.$store.state.productsStore.filter_platform;
    },
    filter_type_product() {
      return this.$store.state.productsStore.filter_type;
    },
    showModalPayment() {
      return this.$store.getters["cartStore/hasProductSelected"];
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  @apply text-lg font-medium text-gray-500;
}

.spacer {
  width: 74%;
  @apply border border-dashed mt-4 mx-auto;
}

.button {
  max-width: 320px;
  width: 100%;
  @apply bg-blue-50 py-2 rounded-full font-bold text-gray-500 cursor-pointer;

  &:hover {
    @apply bg-indigo-100;
  }
  &.active {
    @apply bg-indigo-500 text-white;
  }
}
.grid-menu {
  @apply grid grid-cols-1 gap-4 mt-8;
  .button-menu {
    @apply flex justify-center;
    &.button1 {
    }
    &.button2 {
    }
  }
}

@screen sm {
  .grid-menu {
    @apply grid-cols-2 gap-8;
    .button-menu {
      @apply flex;
      &.button1 {
        @apply justify-end;
      }
      &.button2 {
        @apply justify-start;
      }
    }
  }
}

.group-filters {
  @apply flex flex-col items-center mt-4 gap-2;
  .button {
    &:hover {
      @apply bg-green-100;
    }
    &.active {
      @apply bg-green-500;
    }
  }
}

@screen sm {
  .group-filters {
    @apply flex flex-row justify-center mt-4 gap-2;
  }
}
</style>